import "./App.css";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { initGA } from "./ga-utils";
import { useEffect } from "react";

function App() {
  const handleCookiAccept = () => {
    initGA("G-MKGQ7QZEY2");
  };
  const handleCookiDecline = () => {
    Cookies.remove("_gta");
    Cookies.remove("_ga");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent) {
      handleCookiAccept();
    }
  }, []);
  return (
    <>
      <header className="app-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-9 col-12">
              <p className="header__upperSubTitle">
                Spotkanie kierowników jednostek uczestniczących w wykonywaniu
                <br />
                procedur transplantacyjnych.
              </p>
              <h1 className="header__mainTitle">
                Algorytm zastosowania leku imlifidaza
              </h1>
              <h2 className="header__lowerSubTitle">
                u chorych wysoko immunizowanych
                <br />
                oczekujących na przeszczep nerki.
              </h2>
            </div>
            <div className="col-sm-3 col-12">
              <div className="header__card">
                <p className="header__card--text--information">
                  TERMIN WYDARZENIA - {process.env.NODE_ENV}
                </p>
                <p className="header__card--text">
                  <span className="font-weight-bold">
                    17.11.2022 godzina 15:00
                  </span>
                </p>
                <p className="header__card--text--information">EKSPERCI:</p>
                <p className="header__card--text">
                  <span className="font-weight-bold">Prowadząca</span> - Prof.
                  dr hab. n. med. Magdalena Durlik
                </p>
                <p className="header__card--text">
                  Dr n. med. Dorota Lewandowska
                  <br />
                  Mgr Agnieszka Jałbrzykowska
                  <br />
                  Mgr Edyta Karpeta
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <CookieConsent
        location="bottom"
        buttonText="Zgadzam się"
        enableDeclineButton
        declineButtonText="Odmawiam"
        onDecline={handleCookiDecline}
        onAccept={handleCookiAccept}
        cookieName="iconsentformycookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        W celu świadczenia usług na najwyższym poziomie w ramach naszej witryny
        stosujemy pliki cookies. Pliki cookies umożliwiają nam zbieranie
        anonimowych danych statystycznych w celu usprawnienia działania witryny,
        a po uzyskaniu Twojej zgody, umożliwiają nam dokonywanie pomiarów i
        analiz korzystania z witryny (analityczne pliki cookie).
      </CookieConsent>
    </>
  );
}

export default App;
