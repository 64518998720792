import "./Player.css";
import camera from "./camera-ico.svg";
import message from "./message-ico.svg";
import outroIframe from "./iframe-mask.png";
import outroChat from "./chat-mask.png";
import { useEffect, useState } from "react";

function Player() {
  const [width, setWidth] = useState();
  const [loading, setLoading] = useState(true);

  window.addEventListener("resize", function () {
    setWidth(window.innerWidth);
  });

  function checkIframeLoaded() {
    let iframe = document.getElementById("iframe-player");
    let iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    console.log(iframeDoc.readyState);
    if (iframeDoc.readyState == "complete") {
      setLoading(false);
      console.log(loading);

      return;
    }
    window.setTimeout(checkIframeLoaded, 100);
  }

  useEffect(() => {
    checkIframeLoaded();
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    };
    updateWindowDimensions();
  }, []);

  return (
    <main className="App__main">
      <div className="container">
        <div className="row">
          <div className="col-12 iframe-video">
            {loading && (
              <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            <figure className="iframe-wrapper">
              {/* <img
                src={outroIframe}
                className="iframe--position"
                alt="Zaślepka dla playera"
              /> */}
              {/* <iframe
                className="iframe--position"
                title="Video Player"
                src="https://vimeo.com/event/2618511/embed"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe> */}
              <iframe
                className="iframe--position"
                id="iframe-player"
                title="Video Player"
                src="https://player.vimeo.com/video/773737358?h=448d56f250"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            </figure>
            <div className="container mobile--view mb-5">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="player__text__wrapper d-flex justify-content-start align-items-center">
                    <img src={camera} alt="Camera icon" />
                    <div className="player__text">
                      Materiał będzie dostępny
                      <br />w wersji VOD po spotkaniu
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="player__text__wrapper d-flex justify-content-start mt-md-0 mt-4 align-items-center">
                    <img src={message} alt="Message icon" />
                    <div className="player__text">
                      Zachęcamy do zadawania pytań
                      <br />
                      ekspertom podczas spotkania
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-4 col-12">
            <figure
              className={width <= 767 ? "mobile-chat" : "iframe-wrapper chat"}
            >
              <img
                src={outroChat}
                className="iframe--position"
                alt="Zaślepka dla czatu"
              />
              <iframe
                className="iframe--position"
                title="Chat"
                src="https://vimeo.com/event/2618511/chat/"
                frameBorder="0"
              ></iframe>
            </figure>
          </div> */}
        </div>
      </div>
      <a href="#agenda-href" aria-label="Strzałka w dół do agendy">
        <div className="arrow_down"></div>
      </a>
    </main>
  );
}

export default Player;
