import "./Agenda.css";
import Card from "./Card";

const scrollUpHandler = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

function UpBooton() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <button className="btn btn__scroll" onClick={scrollUpHandler}>
            zobacz transmisję
          </button>
        </div>
      </div>
    </div>
  );
}

function Agenda() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <p className="agenda__title" id="agenda-href">
            Agenda wydarzenia
          </p>
          <div className="cards_agenda">
            <Card
              number={"1"}
              time={"15:00 – 15:30"}
              cols={"Prof. dr hab. n. med. Magdalena Durlik"}
              agendaList={[
                "Powitanie, plan spotkania.",
                "Nowe możliwości w odczulaniu wosoko immunizowanych chorych oczekujących na przeszczepienie nerki.",
              ]}
            />
            <Card
              number={"2"}
              time={"15:00 – 15:30"}
              cols={
                "Dr n. med. Dorota Lewandowska, Mgr Agnieszka Jałbrzykowska"
              }
              agendaList={[
                "Zadania KLO i PZT w przygotowaniu chorego wysoko immunizowanego do przeszczepienia nerki.",
              ]}
            />
            <Card
              number={"3"}
              time={"15:55 – 16:10"}
              cols={"Mgr Edyta Karpeta"}
              agendaList={[
                "Koordynacja przeszczepień nerki u pacjentów wysoko immunizowanych.",
              ]}
            />
            <Card
              number={"4"}
              time={"16:10 – 16:30"}
              cols={"Prof. dr hab. n. med. Magdalena Durlik"}
              agendaList={["Dyskusja, pytania i zakończenie spotkania."]}
            />
          </div>
          <UpBooton />
        </div>
      </div>
    </div>
  );
}
export default Agenda;
