import "./Footer.css";
import regulaminCoockies from "./polityka_prywatnosci_i_cookies.pdf";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer__wrapper">
              <ul className="footer__nav">
                <li className="footer__nav__element">
                  <a
                    href={regulaminCoockies}
                    className="footer__link"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Polityka prywatności i coockies"
                  >
                    Polityka&nbsp;prywatności&nbsp;i&nbsp;plików cookies
                  </a>
                </li>

                <li className="footer__nav__element">
                  <a
                    href="https://www.ema.europa.eu/documents/product-information/idefirix-epar-product-information_pl.pdf"
                    className="footer__link referalnumber"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Charakterystyka produktu leczniczego"
                  >
                    Charakterystyka&nbsp;produktu&nbsp;leczniczego
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
