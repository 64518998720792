import "./Card.css";

function Card({ time, cols, agendaList, number }) {
  return (
    <div className="card__wrapper">
      <span className="card__number">{number}</span>
      <div className="card__content">
        <p className="card__time">{time}</p>
        <p className="card__cols">{cols}</p>
        <ul className="card__agenda">
          {agendaList.map((el, index) => (
            <li className="card__agenda__element" key={index}>
              <span>{el}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Card;
